<template>
  <el-row class="table">
    <el-row class="row-bg">
      <!-- <date-picker ref="dataPicker" :dateTime="dateTime" @updateView="datetTimeGet" class="mb-10"></date-picker> -->
      <el-date-picker
        v-model="dateTime"
        type="year"
        value-format="yyyy"
        class="mb-10 mr-10"
        @change="handleChange"
        placeholder="选择年份">
      </el-date-picker>
      <el-button class="tb-button" @click="search" type="primary">查 询</el-button>
      <el-button class="tb-button" type="info" @click="snapshot">生成快照</el-button>
      <!-- <el-popover
        placement="top"
        width="500"
        trigger="click"
        ref="pover"
        class="mr-10"
        @show="showPopover"
      >
        <el-row style="padding: 20px 0">
          <el-steps :active="active" finish-status="success">
            <el-step title="填写快照基础数据" :status="firstStatus"></el-step>
            <el-step title="快照可行性检查" :status="secondStatus"></el-step>
          </el-steps>
        </el-row>

        <template v-if="active === 0">
          <el-form ref="formData" :model="formData" :rules="rules" label-position="top">
            <el-form-item label="起止时间" prop="time">
              <el-date-picker
                v-model="formData.time"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                clearable
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否删除同时段的旧快照" prop="delSameTime">
              <el-radio-group v-model="formData.delSameTime" size="mini">
                <el-radio-button :label="true">是</el-radio-button>
                <el-radio-button :label="false">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </template>
        <template v-else-if="active === 1">
          <el-alert
            title="检查计算薪酬的必要参数是否都设置好"
            type="warning"
            class="mb-10"
            show-icon>
          </el-alert>
          <el-row class="mb-10">
            <el-button type="primary" size="mini" @click="checkData" v-if="checkDataStatus">查看不完整数据</el-button>
            <el-button type="info" size="mini" @click="checkDetection" v-if="checkDataStatus">重新检测</el-button>
          </el-row>
        </template>

        <div style="text-align: right; margin: 0">
          <el-button type="primary" size="mini" @click="next" v-if="active <= 1">下一步</el-button>
          <template v-else-if="active > 0">
            <el-button type="primary" size="mini" @click="generateSnapshot">确定</el-button>
            <el-button plain size="mini" @click="prev">上一步</el-button>
          </template>
        </div>
        <el-button slot="reference" class="tb-button" type="info">生成快照</el-button>
      </el-popover> -->
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="起止时间" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.min.substring(0, 10)}}</span> ~
          <span>{{scope.row.max.substring(0, 10)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="preview(scope.row)">查看报表</el-button>
          <el-button type="text" @click="update(scope.row)">更新快照薪酬</el-button>
          <!-- <el-button type="text" @click="statistics(scope.row)">我的课时统计</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <create-snapshot :show="snapshotDialog" @close="snapshotDialog=false" @updateView="getListSnapshot"></create-snapshot>

    <sele-filtration :show="filtrationDialog" @close="filtrationDialog=false" :minTime="minTime" :maxTime="maxTime" :ssid="ssid"></sele-filtration>

    <!-- 历史报表 -->
    <history-report :show="reportDialog" @close="reportDialog=false;reportData=[]" @historyParams="historyParams" :reportData="reportData" :minTime="minTime" :maxTime="maxTime" :ssid="ssid" :total="total"></history-report>

  </el-row>
</template>

<script>
import { getListSnapshot, updateSnapshotSalary } from '@/api/salary'
import DatePicker from '@/components/date-picker'
import { formatDateYMD } from '@/utils/formatDate'
import createSnapshot from './components/generateSnapshot'
import seleFiltration from './components/seleFiltration'
import HistoryReport from '@/components/history-report'

export default {
  components: { DatePicker, createSnapshot, seleFiltration, HistoryReport },
  created() {
    let year = new Date().getFullYear()
    this.dateTime = year+''
    // start.setDate(1)
    // end.setDate(d.getDate())
    // this.dateTime = [formatDateYMD(start), formatDateYMD(end)]
    this.minStartTime = `${year}-01-01`
    this.maxStartTime = `${year}-12-31`
    this.getListSnapshot()
  },
  data () {
    return {
      filtrationDialog: false,
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      reportDialog: false,
      dateTime: '',
      minStartTime: '',
      maxStartTime: '',
      snapshotDialog: false,
      minTime: '',
      maxTime: '',
      ssid: '',
      reportData: [],
      total: 0,
    }
  },
  methods: {
    async getListSnapshot () {
      if (!this.minStartTime || !this.maxStartTime) {
        window.$msg('请选择起止时间', 2)
        return false
      }
      const res = await getListSnapshot({
        st: this.minStartTime + ' 00:00:00',
        et: this.maxStartTime + ' 23:59:59',
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    handleChange(val) {
      this.minStartTime = `${val}-01-01`
      this.maxStartTime = `${val}-12-31`
    },

    historyParams(data) {
      let datas = JSON.parse(data)
      if (datas.campusIds && datas.campusIds.lenght !== 0) {
        this.formData.campusIds = datas.campusIds
      }
      if (datas.classIdsExclude && datas.classIdsExclude.lenght !== 0) {
        this.formData.classIdsExclude = datas.classIdsExclude
      }
      this.isProps = true
      this.getSumSalary(data)
    },

    // showPopover() {
    //   this.active = 0
    //   this.firstStatus = null
    //   this.secondStatus = null
    //   this.checkDialog = false
    //   this.checkArr = []
    //   this.formData = {}
    //   this.$refs.formData.resetFields()
    // },

    search() {
      this.pagination.currentPage = 1
      this.getListSnapshot()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListSnapshot()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListSnapshot()
    },

    // 查看报表
    preview(row) {
      this.ssid = row.id
      this.minTime = row.min
      this.maxTime = row.max
      this.reportDialog = true
      // this.$router.push({
      //   path: '/statistics/dollars-detail',
      //   query: {
      //     id: row.id,
      //     min: row.min,
      //     max: row.max
      //   }
      // })
    },

    async update(row) {
      await updateSnapshotSalary({
        ssid: row.id
      })
      window.$msg('更新成功')
    },

    statistics(row) {
      this.$router.push({
        path: '/statistics/hour-statistics',
          query: {
            id: row.id,
          }
        })
    },

    snapshot() {
      this.snapshotDialog = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
