var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        top: "5vh",
        width: "60%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("生成快照")])]
      ),
      _c(
        "el-row",
        { staticStyle: { padding: "20px 0" } },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active, simple: "" } },
            [
              _c("el-step", {
                attrs: {
                  title: "填写快照基础数据",
                  status: _vm.firstStatus,
                  icon: "el-icon-edit",
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "快照可行性检查",
                  status: _vm.secondStatus,
                  icon: "el-icon-picture",
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "完成",
                  status: _vm.thirdlyStatus,
                  icon: "el-icon-circle-check",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.active === 1
        ? [
            _c(
              "el-form",
              {
                ref: "formData",
                staticStyle: { "padding-left": "50px" },
                attrs: {
                  model: _vm.formData,
                  rules: _vm.rules,
                  "label-position": "top",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "起止时间", prop: "time" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "unlink-panels": "",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "value-format": "yyyy-MM-dd",
                        clearable: "",
                        "picker-options": _vm.pickerOptions,
                      },
                      model: {
                        value: _vm.formData.time,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "time", $$v)
                        },
                        expression: "formData.time",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "是否删除同时段的旧快照",
                      prop: "delSameTime",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.formData.delSameTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "delSameTime", $$v)
                          },
                          expression: "formData.delSameTime",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: true } }, [
                          _vm._v("是"),
                        ]),
                        _c("el-radio-button", { attrs: { label: false } }, [
                          _vm._v("否"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.active === 2
        ? [
            _vm.checkArr.length !== 0
              ? [
                  _c("el-alert", {
                    staticClass: "mb-10",
                    attrs: {
                      title: "检查计算薪酬的必要参数是否都设置好",
                      type: "warning",
                      "show-icon": "",
                    },
                  }),
                  _c(
                    "el-row",
                    { staticClass: "unusual-container" },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.checkArr,
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "类型",
                              align: "center",
                              prop: "reason",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "详情",
                              align: "center",
                              prop: "reasonMsg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _c("el-alert", {
                  staticClass: "mb-10",
                  attrs: {
                    title: "完成快照检查",
                    type: "success",
                    "show-icon": "",
                  },
                }),
            _c(
              "el-row",
              { staticClass: "check-container mb-10 mt-15" },
              [
                _vm.checkDataStatus
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "info", size: "mini" },
                        on: { click: _vm.checkDetection },
                      },
                      [_vm._v("重新检测")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm.active === 3
        ? [
            _c(
              "div",
              { staticClass: "user_bg" },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "user_card mt-15",
                    attrs: { shadow: "never" },
                  },
                  [
                    _c("i", { staticClass: "el-icon-circle-check" }),
                    _c("h3", [_vm._v("操作成功")]),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.active === 2
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.checkArr.length !== 0,
                    loading: _vm.generateLoad,
                  },
                  on: { click: _vm.generateSnapshot },
                },
                [_vm._v("生 成")]
              )
            : _vm._e(),
          _vm.active > 1 && _vm.active < 3
            ? _c(
                "el-button",
                { attrs: { plain: "", size: "mini" }, on: { click: _vm.prev } },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _vm.active <= 1
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.next },
                },
                [_vm._v("下一步")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }