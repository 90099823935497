<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    top="5vh"
    width="60%"
    @open="openDialog"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>生成快照</span>
    </div>
    <!-- active：{{active}}
    <hr> -->
    <el-row style="padding: 20px 0">
      <el-steps :active="active" simple>
        <el-step title="填写快照基础数据" :status="firstStatus" icon="el-icon-edit"></el-step>
        <el-step title="快照可行性检查" :status="secondStatus" icon="el-icon-picture"></el-step>
        <el-step title="完成" :status="thirdlyStatus" icon="el-icon-circle-check"></el-step>
      </el-steps>
    </el-row>

    <template v-if="active === 1">
      <el-form ref="formData" :model="formData" :rules="rules" label-position="top" style="padding-left: 50px;">
        <el-form-item label="起止时间" prop="time">
          <el-date-picker
            v-model="formData.time"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            clearable
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否删除同时段的旧快照" prop="delSameTime">
          <el-radio-group v-model="formData.delSameTime" size="mini">
            <el-radio-button :label="true">是</el-radio-button>
            <el-radio-button :label="false">否</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </template>
    <template v-else-if="active === 2">
      <template v-if="checkArr.length !== 0">
        <el-alert
          title="检查计算薪酬的必要参数是否都设置好"
          type="warning"
          class="mb-10"
          show-icon>
        </el-alert>
        <el-row class="unusual-container">
          <el-table
            :data="checkArr"
            border
            fit
            highlight-current-row
          >
            <el-table-column label="类型" align="center" prop="reason" width="200"></el-table-column>
            <el-table-column label="详情" align="center" prop="reasonMsg"></el-table-column>
          </el-table>
        </el-row>
      </template>

      <el-alert
        v-else
        title="完成快照检查"
        type="success"
        class="mb-10"
        show-icon>
      </el-alert>
      <el-row class="check-container mb-10 mt-15">
        <!-- <el-button type="danger" size="mini" @click="checkData" v-if="checkDataStatus" :disabled="checkArr.length === 0">查看不完整数据</el-button> -->
        <el-button type="info" size="mini" @click="checkDetection" v-if="checkDataStatus">重新检测</el-button>
      </el-row>
    </template>

    <template v-else-if="active === 3">
      <!-- 完成条件 -->
      <div class="user_bg">
        <el-card class="user_card mt-15" shadow="never">
          <i class="el-icon-circle-check"></i>
          <h3>操作成功</h3>
          <!-- <el-button type="primary" size="mini" style="margin-top: 15px;" @click="back">查看统计</el-button>
          <el-button plain size="mini" style="margin-top: 15px;" @click="back">更新快照薪酬</el-button> -->
        </el-card>
      </div>
    </template>

    <el-row slot="footer">
      <el-button type="primary" size="mini" @click="generateSnapshot" v-if="active === 2" :disabled="checkArr.length !== 0" :loading="generateLoad">生 成</el-button>
      <el-button plain size="mini" @click="prev" v-if="active > 1 && active < 3">上一步</el-button>
      <el-button type="primary" size="mini" @click="next" v-if="active <= 1">下一步</el-button>
    </el-row>

    <!-- <check-arr :show="checkDialog" @close="checkDialog=false" :checkArr="checkArr"></check-arr> -->
  </el-dialog>
</template>

<script>
// import CheckArr from '@/components/statistics/check-arr/'
import {generateSnapshot, checkIntegrity } from '@/api/salary'

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  // components: {CheckArr},
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data() {
    return{
      active: 1,
      formData: {},
      firstStatus: null,
      secondStatus: null,
      thirdlyStatus: null,
      rules: {
        time: [{ required: true, message: '请选择起止时间', trigger: 'blur' }],
        delSameTime: [{ required: true, message: '请选择是否删除同时段的旧快照', trigger: 'blur' }],
      },
      checkDialog: false,
      checkDataStatus: false,
      checkArr: [],
      generateLoad: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '本周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const weekday = start.getDay() === 0 ? start.getDay() + 7 : start.getDay()// getDay() 方法可返回一周（0~6）的某一天的数字。
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '上周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const weekday = start.getDay() === 0 ? start.getDay() + 14 : start.getDay() + 7
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '下周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const weekday = start.getDay() === 0 ? start.getDay() : start.getDay() - 7
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '本月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              var year = start.getFullYear();
              var month = start.getMonth()+1;
              var d = new Date(year, month, 0);
              start.setDate(1);
              end.setDate(d.getDate());
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: '上月',
            onClick(picker) {
              const start = new Date();
              const end = new Date(start);
              end.setMonth(start.getMonth());
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              start.setDate(1);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
    }
  },
  methods: {
    openDialog() {
      this.active = 1
      this.firstStatus = null
      this.secondStatus = null
      this.checkDialog = false
      this.checkArr = []
      this.formData = {}
    },

    close() {
      this.$emit('close')
      this.$emit('updateView')
    },

    next() {
      if (this.active === 1) {
        this.$refs.formData.validate(valid => {
          if (valid) {
            this.checkStat()
            this.active++
          }
        })
      } else if (this.active === 2) {
      }
    },

    // 生成快照
    generateSnapshot() {
      this.generateLoad = true
      generateSnapshot({
        st: this.formData.time[0] + ' 00:00:00',
        et: this.formData.time[1] + ' 23:59:59',
        delSameTime: this.formData.delSameTime
      }).then(res => {
        if (res.state === 'success') {
          this.active ++
          window.$msg('生成成功')
          // this.generateLoad = false
          // this.close()
          // this.$emit('updateView')
        }
      })
    },

    prev() {
      this.active--
    },

    // 查看不完整数据
    checkData () {
      this.checkDialog = true
    },

    checkDetection () {
      this.checkDataStatus = false
      this.checkStat()
    },

    checkStat() {
      checkIntegrity({
        st: this.formData.time[0] + ' 00:00:00',
        et: this.formData.time[1] + ' 23:59:59',
      }).then(res => {
        if (res.state === 'success' && res.body.lenght === 0) {
        } else if (res.state === 'success' && res.body.lenght !== 0) {
          this.checkDataStatus = true
          this.checkArr = res.body
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';

/deep/ .el-dialog__body {
  padding-top: 0;
}

.check-container {
  display: flex;
  justify-content: center;
}

.user_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .user_card {
    width: 500px;
    height: auto;
    overflow: auto;
    text-align: center;
    padding: 20px 20px 20px 20px;
    .el-icon-circle-check {
      font-size: 70px;
      color: #52C41A;
    }
    h3 {
      font-size: 24px;
      color: #272727;
      font-weight: 650;
      margin-right: 0;
      margin-top: 25px;
    }
    .succeed-hint {
      font-size: 14px;
      color: #8D8D8D;
      display: block;
      margin-top: 15px;
    }
  }
}

.unusual-container {
  width: 100%;
  max-height: 350px;
  overflow: auto;
}
</style>
